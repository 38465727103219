import crypto from 'node:crypto'
import { z } from 'zod'
import type { H3Event } from 'h3'
import type { ExtendedPrismaClient } from './middleware/1.prisma'
import type { Context } from './trpc/context'
import type { DefaultSession } from '~/next-auth'

/**
 * This will be used to stringfy string array
 * This conversion is to save string array as string in database
 * as sqlite doesn't support string[] type
 * This only takes String array. To allow other types please adjust `parseStringArray`
 */
export const useArrayAsString = (arr: string[] | null | undefined): string => {
  if (!arr || !arr.length) {
    return ''
  }

  const parseStringArray = z.array(z.string()).safeParse(arr)
  if (!parseStringArray.success) {
    return ''
  }

  const value = JSON.stringify(arr)
  return value
}

/**
 * This will be used to parse string to array before we use stringfied array
 * This conversion is to save string array as string in database
 * as sqlite doesn't support string[] type
 * This only takes String array. To allow other types please adjust `parseStringArray`
 */
export const useStringAsArray = (arrayString: string | null | undefined): string[] => {
  if (!arrayString) {
    return []
  }

  const trimmedString = arrayString.trim()
  // arrayString should be in formatted as `[...]`
  if (!trimmedString.startsWith('[') || !trimmedString.endsWith(']')) {
    return []
  }

  const parsedArray = JSON.parse(trimmedString)
  const parseStringArray = z.array(z.string()).safeParse(parsedArray)
  if (!parseStringArray.success) {
    return []
  }

  return parsedArray
}

export const getRequestBaseURL = (event: H3Event) => `${getRequestProtocol(event)}://${getRequestHost(event)}`
export const getRandomFloat = (min: number, max: number) => Math.random() * (max - min) + min
export const sleep = (durationMs: number) => new Promise(resolve => setTimeout(resolve, durationMs))
export const isProduction = process.env.NODE_ENV === 'production'

export const generateConnectById = (values: string[]) => {
  return values.map((value) => {
    return { id: value }
  })
}

export const getPersonIds = (session?: DefaultSession | null) => {
  if (session?.user.role !== 'institution') {
    return [
      session?.user.id,
      session?.user.personId,
      ...(session?.user.legalRepresentativeOf ?? []),
    ]
  }
  return [
    session.user.id,
    session.user.institutionId,
  ]
}

export const handleApiErrors = (error: any) => {
  if (error?.code === 'P2025') {
    return createError({ status: 404, statusMessage: 'Not Found' })
  }
  return createError({ status: 500, statusMessage: 'Internal Server Error' })
}

export const createApiContext = async (event: H3Event) => {
  return {
    host: `${getRequestProtocol(event)}://${getRequestHost(event)}`,
    prisma: event.context.prisma as ExtendedPrismaClient,
    session: { user: { role: 'admin' } },
    event,
  } as Context
}

export const generateSecureRandomCode = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const randomBytes = crypto.randomBytes(length)
  let result = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = randomBytes[i] % characters.length
    result += characters[randomIndex]
  }

  return result
}
