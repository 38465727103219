import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAccessibilityEye, LazySvgoAccessibilityEyeClosed, LazySvgoAccessibilityWheelchair, LazySvgoCommunicationCalender, LazySvgoCommunicationDialoge, LazySvgoCommunicationIdentification, LazySvgoCommunicationInfo, LazySvgoCommunicationMail, LazySvgoCommunicationNotePlus, LazySvgoCommunicationPhone, LazySvgoCommunicationWarning, LazySvgoDigitalClock, LazySvgoDigitalClose, LazySvgoDigitalCloud, LazySvgoDigitalComputerFile, LazySvgoDigitalSearch, LazySvgoDirectionDown, LazySvgoDirectionLeft, LazySvgoDirectionRight, LazySvgoDirectionUp, LazySvgoEnvironmentEarth, LazySvgoLogoIcon, LazySvgoLogoTextDe, LazySvgoLogoTextEn, LazySvgoPeopleGroup, LazySvgoPeople, LazySvgoPeoplePlus, LazySvgoPeoplePerson, LazySvgoPeoplePersonExchange, LazySvgoPeoplePersonFlow, LazySvgoPeoplePersonIdea, LazySvgoPeoplePersonTalk, LazySvgoPeopleTeamwork, LazySvgoPlacesCity, LazySvgoPlacesHospital, LazySvgoPlacesHouse, LazySvgoPlacesMap, LazySvgoPlacesPin, LazySvgoPlacesStreet, LazySvgoTopicsAid, LazySvgoTopicsCog, LazySvgoTopicsComputerBook, LazySvgoTopicsComputerDocument, LazySvgoTopicsCovid, LazySvgoTopicsDocumentEdit, LazySvgoTopicsExplosion, LazySvgoTopicsFolderMonitor, LazySvgoTopicsHeartLine, LazySvgoTopicsHygine, LazySvgoTopicsList, LazySvgoTopicsMask, LazySvgoTopicsPenWhite, LazySvgoTopicsQuiet, LazySvgoTopicsReport, LazySvgoTopicsSettingMonitor, LazySvgoTopicsTemperature, LazySvgoTopicsTrash, LazySvgoUiChevronDown, LazySvgoUiContentCopy, LazySvgoUiFileTree, LazySvgoUiFullscreen, LazySvgoUiFullscreenExit, LazySvgoUiFunnel, LazySvgoUiFunnelPlus, LazySvgoUiKeyhole, LazySvgoUiLoading, LazySvgoUiLogout, LazySvgoUiMenu, LazySvgoUiRefresh, LazySvgoUiTrashCan, LazySvgoUiUserCircle, LazyBasic, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["SvgoAccessibilityEye", LazySvgoAccessibilityEye],
["SvgoAccessibilityEyeClosed", LazySvgoAccessibilityEyeClosed],
["SvgoAccessibilityWheelchair", LazySvgoAccessibilityWheelchair],
["SvgoCommunicationCalender", LazySvgoCommunicationCalender],
["SvgoCommunicationDialoge", LazySvgoCommunicationDialoge],
["SvgoCommunicationIdentification", LazySvgoCommunicationIdentification],
["SvgoCommunicationInfo", LazySvgoCommunicationInfo],
["SvgoCommunicationMail", LazySvgoCommunicationMail],
["SvgoCommunicationNotePlus", LazySvgoCommunicationNotePlus],
["SvgoCommunicationPhone", LazySvgoCommunicationPhone],
["SvgoCommunicationWarning", LazySvgoCommunicationWarning],
["SvgoDigitalClock", LazySvgoDigitalClock],
["SvgoDigitalClose", LazySvgoDigitalClose],
["SvgoDigitalCloud", LazySvgoDigitalCloud],
["SvgoDigitalComputerFile", LazySvgoDigitalComputerFile],
["SvgoDigitalSearch", LazySvgoDigitalSearch],
["SvgoDirectionDown", LazySvgoDirectionDown],
["SvgoDirectionLeft", LazySvgoDirectionLeft],
["SvgoDirectionRight", LazySvgoDirectionRight],
["SvgoDirectionUp", LazySvgoDirectionUp],
["SvgoEnvironmentEarth", LazySvgoEnvironmentEarth],
["SvgoLogoIcon", LazySvgoLogoIcon],
["SvgoLogoTextDe", LazySvgoLogoTextDe],
["SvgoLogoTextEn", LazySvgoLogoTextEn],
["SvgoPeopleGroup", LazySvgoPeopleGroup],
["SvgoPeople", LazySvgoPeople],
["SvgoPeoplePlus", LazySvgoPeoplePlus],
["SvgoPeoplePerson", LazySvgoPeoplePerson],
["SvgoPeoplePersonExchange", LazySvgoPeoplePersonExchange],
["SvgoPeoplePersonFlow", LazySvgoPeoplePersonFlow],
["SvgoPeoplePersonIdea", LazySvgoPeoplePersonIdea],
["SvgoPeoplePersonTalk", LazySvgoPeoplePersonTalk],
["SvgoPeopleTeamwork", LazySvgoPeopleTeamwork],
["SvgoPlacesCity", LazySvgoPlacesCity],
["SvgoPlacesHospital", LazySvgoPlacesHospital],
["SvgoPlacesHouse", LazySvgoPlacesHouse],
["SvgoPlacesMap", LazySvgoPlacesMap],
["SvgoPlacesPin", LazySvgoPlacesPin],
["SvgoPlacesStreet", LazySvgoPlacesStreet],
["SvgoTopicsAid", LazySvgoTopicsAid],
["SvgoTopicsCog", LazySvgoTopicsCog],
["SvgoTopicsComputerBook", LazySvgoTopicsComputerBook],
["SvgoTopicsComputerDocument", LazySvgoTopicsComputerDocument],
["SvgoTopicsCovid", LazySvgoTopicsCovid],
["SvgoTopicsDocumentEdit", LazySvgoTopicsDocumentEdit],
["SvgoTopicsExplosion", LazySvgoTopicsExplosion],
["SvgoTopicsFolderMonitor", LazySvgoTopicsFolderMonitor],
["SvgoTopicsHeartLine", LazySvgoTopicsHeartLine],
["SvgoTopicsHygine", LazySvgoTopicsHygine],
["SvgoTopicsList", LazySvgoTopicsList],
["SvgoTopicsMask", LazySvgoTopicsMask],
["SvgoTopicsPenWhite", LazySvgoTopicsPenWhite],
["SvgoTopicsQuiet", LazySvgoTopicsQuiet],
["SvgoTopicsReport", LazySvgoTopicsReport],
["SvgoTopicsSettingMonitor", LazySvgoTopicsSettingMonitor],
["SvgoTopicsTemperature", LazySvgoTopicsTemperature],
["SvgoTopicsTrash", LazySvgoTopicsTrash],
["SvgoUiChevronDown", LazySvgoUiChevronDown],
["SvgoUiContentCopy", LazySvgoUiContentCopy],
["SvgoUiFileTree", LazySvgoUiFileTree],
["SvgoUiFullscreen", LazySvgoUiFullscreen],
["SvgoUiFullscreenExit", LazySvgoUiFullscreenExit],
["SvgoUiFunnel", LazySvgoUiFunnel],
["SvgoUiFunnelPlus", LazySvgoUiFunnelPlus],
["SvgoUiKeyhole", LazySvgoUiKeyhole],
["SvgoUiLoading", LazySvgoUiLoading],
["SvgoUiLogout", LazySvgoUiLogout],
["SvgoUiMenu", LazySvgoUiMenu],
["SvgoUiRefresh", LazySvgoUiRefresh],
["SvgoUiTrashCan", LazySvgoUiTrashCan],
["SvgoUiUserCircle", LazySvgoUiUserCircle],
["Basic", LazyBasic],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
