import validate from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/pages/runtime/validate.js";
import authorization_45global from "/app/middleware/authorization.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.3_@azure+identity@4.4.0_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@8.57.0_io_72liswitvc5j3cmjkorex4v3xm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authorization_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.4_@azure+identity@4.4.0_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@_l7ig6h2ubo5esey6uzbndcat6u/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}